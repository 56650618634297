<template>
  <div class="scenesMap">
    <div id="map"></div>
    <sceneInfo :scenicSpotData="scenicSpotData"></sceneInfo>
  </div>
</template>
<script>
import mapMixin from "@/mixins/mapMixin.vue";
import request from "@/api/index";
// import request from "@/utils/request";
import Tonglu from "@/api/home/Tonglu.json";
import sceneInfo from "./components/sceneInfo";
export default {
  name: "industryMap",
  mixins: [mapMixin],
  components: {
    sceneInfo,
  },
  data() {
    return {
      scenes: [],
      scenicSpotData: {},
    };
  },
  methods: {
    getSceneList() {
      request.post2("getScenicSpotList").then((res) => {
        console.log("res", res.data);
        const data = res.data;

        // request.post("/common/getScenicSpotList").then((res) => {
        //   const data = res.data;
        if (typeof data == "string") {
          this.$msgError(data);
          return;
        }
        this.scenicSpotData = data[0];
        this.scenes = data.map((item) => {
          let d = {
            name: item.scenicSpotName,
            id: item.id,
          };
          if (item.id == this.scenicSpotId) {
            this.scenicSpotData = item;
          }
          return d;
        });
        console.log("scenicSpotData", this.scenicSpotData);
        this.getscenicspotdata([this.scenicSpotData]);
      });
    },
    // 获取景区数据
    getscenicspotdata(item) {
      this.addAreaLayer(Tonglu.features[0].geometry.coordinates[0], {
        zoom: 10,
      });
      this.setLabelMarkers(
        item,
        require("@/assets/industryMap/scene-icon.png")
      );
    },
    // 通过景区ID查询设施列表
    getSubMenu(menu) {},
    // 通过当前设施ID查询当前当前设施的设备信息
    gettwoMap(id) {},
  },
  mounted() {
    this.getSceneList();
    this.initMap();
  },
  computed: {
    scenicSpotId() {
      return this.$store.state.scenicSpotId;
    },
  },
};
</script>
<style lang="less" scope>
.scenesMap {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
#map {
  width: 100%;
  height: 100%;
}
</style>
